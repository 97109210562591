(function (mi, $) {
	mi.timeline = function () {

		let widthHandler = function() {
			const $container = $('input[type="range"]');

			const start_year = $container.attr("min");

			//$(":range").rangeinput();
			$container.rangeslider();

			$container.on("change",function(event) {
				let value = $(this).val();

				//turn off scrollspy
				$('#myScrollspy').removeClass("timeline-scrollspy");

				//check to see if year exists if not find next lowest that does
				if ($("#" + value).length === 0) {
					let closest = parseInt(value);
					while ($("#" + closest).length === 0) {
						closest --;
						if (closest < start_year) {
							$('html, body').animate({
								scrollTop: $(".main").offset().top - 195
							}, 1000);
							break;
						}
					}
					value = closest;
				}

				if ($('[data-timeline]').hasClass('fixed')) {
					$('html, body').animate({
						scrollTop: $("#" + value).offset().top - 195
					}, 1000);
				} else {
					$('html, body').animate({
						scrollTop: $("#" + value).offset().top - 305
					}, 1000);
				}

				//turn scrollspy back on
				setTimeout(function(){$('#myScrollspy').addClass("timeline-scrollspy");},1100);
			});
		};

		widthHandler();

		$(window).scroll(mi.timeline_fixDiv);
		mi.timeline_fixDiv();


		$('body').css('position', 'relative').scrollspy({target: '.timeline-scrollspy',offset:200});
		$('.timeline-scrollspy').on('activate.bs.scrollspy', function () {
			let year = $('.timeline-scrollspy .active a').attr("href").replace("#","");

			$('input[type="range"]').data("rangeinput").setValue(year);
		});

	};

	mi.timeline_fixDiv = function () {
		const $cache = $('[data-timeline]');
		const getheaderHeight = $('.header').height();
		if ($(window).scrollTop() > 100) {
			$cache.css({
				'top': getheaderHeight
			});
			$cache.addClass('fixed');

			//$cache.find("[data-range-wrapper]").addClass("container");
		}
		else {
			$cache.css({
				'top': '0'
			});
			$cache.removeClass('fixed');

			//$cache.find("[data-range-wrapper]").removeClass("container");
		}
	};

	mi.filmographyTimeline = function () {
		const $container = $('input[type="range"]');
		const $ruler = $('<div class="rangeslider__labels" />');
		if (!$container.length) {
			return;
		}

		const start_year = $container.attr("min");

		$('body').css('position', 'relative').scrollspy({target: '#myScrollspy',offset:300});
		$('#myScrollspy').on('activate.bs.scrollspy', function () {
			let el = $('.timeline-scrollspy .active a');

			if (el.length > 0) {
				let year = el.attr("href").replace("#", "");

				$('input[type="range"]').val(year).change();

				//turn scrollspy back on (for some reason this isn't being done onSlideEnd when using the change function)
				setTimeout(function () {
					$('#myScrollspy').addClass("timeline-scrollspy");
				}, 1100);
			}
		});

		$container.rangeslider({
			// Feature detection the default is `true`.
			// Set this to `false` if you want to use
			// the polyfill also in Browsers which support
			// the native <input type="range"> element.
			polyfill: false,

			// Default CSS classes
			rangeClass: 'rangeslider',
			disabledClass: 'rangeslider--disabled',
			horizontalClass: 'rangeslider--horizontal',
			fillClass: 'rangeslider__fill',
			handleClass: 'rangeslider__handle',

			// Callback function
			onInit: function() {
				// get range index labels
				$ruler[0].innerHTML = getRulerRange(this.min, this.max, this.step);
				this.$range.prepend($ruler);
			},

			// Callback function
			onSlide: function(position, value) {
				//turn off scrollspy
				$('#myScrollspy').removeClass("timeline-scrollspy");
			},

			// Callback function
			onSlideEnd: function(position, value) {
				let rangeValue = value;
				//check to see if year exists if not find next lowest that does
				if ($("#" + rangeValue).length === 0) {
					let closest = parseInt(rangeValue);
					while ($("#" + closest).length === 0) {
						closest --;
						if (closest < start_year) {
							$('html, body').animate({
								scrollTop: $(".main").offset().top - 195
							}, 1000);
							break;
						}
					}
					rangeValue = closest;

					console.log(closest);
					console.log(start_year);
				}

				if ($('[data-timeline]').hasClass('fixed')) {
					$('html, body').animate({
						scrollTop: $("#" + rangeValue).offset().top - 330
					}, 1000);
				} else {
					$('html, body').animate({
						scrollTop: $("#" + rangeValue).offset().top - 305
					}, 1000);
				}

				//turn scrollspy back on
				setTimeout(function(){$('#myScrollspy').addClass("timeline-scrollspy");},1100);
			}

		});

		function getRulerRange(min, max, step) {
			let range = '';
			let i = min;

			while (i <= max) {
				let label = i;

				/*
				if (i == 1985) {
					label = "Founded 1985";
				}else if (i >= new Date().getFullYear()) {
					label = "Present";
				}
				*/

				if (i%5 == 0) {
					range += '<div class="rangeslider__labels__label">' + label + '</div>';
				}else {
					range += '<div class="rangeslider__labels__mini_tick">&nbsp;</div>';
				}
				i++;
			}
			return range;
		}

		$(window).scroll(mi.timeline_fixDiv);
		mi.timeline_fixDiv();
	};

}(window.mi = window.mi || {}, jQuery));
